<template>
  <div class="upload-btn-wrapper" @click="onClick">
    <input
      ref="input" type="file"
      name="file" class="inputfile"
      :accept="accept" :multiple="multiple"
      @change="onFileChange"
    >
    <label for="file">
      {{ buttonText }}
      <i class="fa fa-upload" />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    buttonText: String,
    accept: String,
    multiple: Boolean
  },
  methods: {
    onClick () {
      this.$refs.input.click()
    },
    onFileChange (event) {
      this.$emit('fileUpload', this.multiple ? event.target.files : event.target.files[0])
    }
  }
}
</script>

<style>
.upload-btn-wrapper {
  display: inline-block;
  border: 1px solid gray;
  border-radius: 8px;
  margin: 5px;
  background-color: white;
}
.inputfile {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  display: inline-block;
  font-weight: normal;
  margin: 0px;
  padding: 2px 4px;
}
.inputfile + label {
  cursor: pointer;
}
</style>
