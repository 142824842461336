<template lang="html">
  <div class="employee-cell">
    <div class="upper-row">
      <div>
        {{ year + ':' }}
      </div>
      <DlgNumericInput
        :value="data.hours"
        :name="name"
        addon="h"
        @input="$emit('updateTrainingHours', $event)"
      />
      <div v-if="!displayCertificates">
        <div v-if="data.certificates.length === 1">
          {{ data.certificates.length }}
          <IxRes>dlgCert.social.training.grid.certificate</IxRes>
        </div>
        <div v-else-if="data.certificates.length > 1">
          {{ data.certificates.length }}
          <IxRes>dlgCert.social.training.grid.certificates</IxRes>
        </div>
        <div v-else :class="{'certificates-missing': certificateMandatory}">
          <IxRes> dlgCert.social.training.grid.noCertificates</IxRes>
        </div>
      </div>
    </div>

    <div v-if="displayCertificates" class="certificates-row">
      <div v-if="!data.certificates.length">
        <div v-if="certificateMandatory" class="certificates-missing">
          <i class="fa fa-exclamation-triangle" />
          <IxRes>dlgCert.social.training.grid.noCertificates</IxRes>
        </div>
        <div v-else>
          <IxRes>dlgCert.social.training.grid.voluntaryCertificateMissing</IxRes>
        </div>
        <CertificateUploadButton button-text="Nachweise hochladen" accept=".jpg,.jpeg,.png,.doc,.docx,.pdf" @fileUpload="handleFileUpload" />
      </div>
      <div v-else>
        <div v-for="(certificate, idx) of data.certificates" :key="idx">
          <IxRes>dlgCert.social.training.grid.certificate</IxRes> {{ (idx+1) + ': ' + certificate }}
          <IxButton rounded icon="trash" @click="$emit('removeCertificate', idx)" />
        </div>
        <CertificateUploadButton button-text="Weitere Nachweise hochladen" accept=".jpg,.jpeg,.png,.doc,.docx,.pdf" @fileUpload="handleFileUpload" />
      </div>
    </div>
  </div>
</template>

<script>
import IxButton from '@components/IxButton'

import DlgNumericInput from '@dlg-cert/components/forms/elements/DlgNumericInput'
import CertificateUploadButton from './CertificateUploadButton'

export default {
  components: {
    CertificateUploadButton,
    DlgNumericInput,
    IxButton
  },
  props: {
    data: Object,
    year: String,
    displayCertificates: Boolean,
    certificateMandatory: Boolean,
    name: String
  },
  methods: {
    handleFileUpload (file) {
      this.$emit('fileUpload', file)
    }
  }
}
</script>

<style lang="scss" scoped>
.employee-cell{
  > * {
    padding: 0.25em 0.5em;
    display: flex;
    align-items: center;
  }
}

.upper-row {
  display: flex;
  > div {
    margin-right: 5px;
  }
}

.certificates-missing {
  color: red;
}
</style>
