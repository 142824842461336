<template lang="html">
  <LoadingWrapper :loading="loading">
    <div v-if="data">
      <DlgHeader>
        <IxRes>dlgCert.social.training.headers.training</IxRes>
      </DlgHeader>

      <div class="training-grid">
        <div class="grid-header category">
          <IxRes>dlgCert.social.training.grid.category</IxRes>
        </div>
        <div class="grid-header category-1 column-with-border">
          <IxRes>dlgCert.social.training.grid.category</IxRes> 1
        </div>
        <div class="grid-header category-2 column-with-border">
          <IxRes>dlgCert.social.training.grid.category</IxRes> 2
        </div>
        <div class="grid-header category-3 column-with-border">
          <IxRes>dlgCert.social.training.grid.category</IxRes> 3
        </div>
        <div class="display-examples">
          <a @click="displayExamples = !displayExamples">
            <IxRes>dlgCert.social.training.grid.displayExamples</IxRes>
          </a>
        </div>
        <div class="category-1-description column-with-border">
          <IxRes>dlgCert.social.training.grid.categoryOneDescription</IxRes>
          <ul v-if="displayExamples">
            <li><IxRes>dlgCert.social.training.grid.stockmanship</IxRes></li>
            <li><IxRes>dlgCert.social.training.grid.precisionFarming</IxRes></li>
            <li><IxRes>dlgCert.social.training.grid.clawCare</IxRes></li>
            <li><IxRes>dlgCert.social.training.grid.pcTraining</IxRes></li>
            <li><IxRes>dlgCert.social.training.grid.userTraining</IxRes></li>
          </ul>
        </div>
        <div class="category-2-description column-with-border">
          <IxRes>dlgCert.social.training.grid.categoryTwoDescription</IxRes>
          <ul v-if="displayExamples">
            <li><IxRes>dlgCert.social.training.grid.tradeShowVisits</IxRes></li>
            <li><IxRes>dlgCert.social.training.grid.fieldDays</IxRes></li>
            <li><IxRes>dlgCert.social.training.grid.architecture</IxRes></li>
            <li><IxRes>dlgCert.social.training.grid.professionalMedia</IxRes></li>
          </ul>
        </div>
        <div class="category-3-description column-with-border">
          <IxRes>dlgCert.social.training.grid.categoryThreeDescription</IxRes>
          <ul v-if="displayExamples">
            <li><IxRes>dlgCert.social.training.grid.fertilizerRequirement</IxRes></li>
            <li><IxRes>dlgCert.social.training.grid.nutrientBilance</IxRes></li>
            <li><IxRes>dlgCert.social.training.grid.crossCompliance</IxRes></li>
            <li><IxRes>dlgCert.social.training.grid.hygieneTraining</IxRes></li>
          </ul>
        </div>

        <div class="employee-column">
          <div>
            <IxRes>dlgCert.social.training.grid.employee</IxRes>
          </div>
          <div>
            <a @click="displayCertificates = !displayCertificates">
              <IxRes>dlgCert.social.training.grid.displayCertificates</IxRes>
            </a>
          </div>
        </div>
        <div class="employee-subgrid">
          <template v-for="(employee, employeeIndex) of data.employees">
            <div :key="`employee-${employeeIndex}-name`" class="employee-name employee-cell">
              {{ employee.employeeShort }}
            </div>
            <EmployeeTrainingCell
              v-for="i in 3"
              :key="`employee-${employeeIndex}-category${i}`" :name="`employee-${employeeIndex}-category${i}`"
              :data="employee[`category${i}`]"
              :display-certificates="displayCertificates" :year="year"
              class="employee-cell column-with-border" :certificate-mandatory="i !== 2"
              @removeCertificate="removeCertificate(employeeIndex, i, $event)"
              @updateTrainingHours="updateTrainingHours(employeeIndex, i, $event)"
              @fileUpload="addCertificate(employeeIndex, i, $event)"
            />
          </template>
        </div>
      </div>
      <IxRes>dlgCert.social.training.minimumOneYearEmployedEmployees</IxRes>
    </div>
  </LoadingWrapper>
</template>

<script>
import LoadingWrapper from './shared/LoadingWrapper'
import DlgHeader from './elements/DlgHeader'

import EmployeeTrainingCell from './elements/social/training/EmployeeTrainingCell'

import FormPartMixin from '@components/forms/FormPartMixin'
import ApiMixin from '@dlg-cert/mixins/ApiMixin'

export default {
  components: {
    DlgHeader,
    EmployeeTrainingCell,
    LoadingWrapper
  },
  mixins: [
    FormPartMixin,
    ApiMixin
  ],
  data () {
    return {
      displayExamples: false,
      displayCertificates: false
    }
  },
  computed: {
    year () {
      return String(this.$route.params.year)
    },
    apiUrl () {
      return `/api/v2/dlg-cert/${this.$route.params.orgUnitId}/social/${this.year}/training`
    }
  },
  methods: {
    async addCertificate (employeeIndex, category, certificate) {
      const employeeId = this.data.employees[employeeIndex].employeeId
      let formData = new FormData()
      formData.append('file', certificate)
      formData.append('category', category)

      try {
        await this.api.post(`/${employeeId}/file/upload`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(() => {
          let certificateName = certificate.name
          this.data.employees[employeeIndex]['category' + category].certificates.push(certificateName)
        })
      } catch (error) {
        console.error(error)
      }
    },
    async removeCertificate (employeeIndex, category, certificateIndex) {
      const fileName = this.data.employees[employeeIndex]['category' + category].certificates[certificateIndex]
      const employeeId = this.data.employees[employeeIndex].employeeId
      try {
        await this.api.delete(`/${employeeId}/file/${category}/${fileName}`)
        .then(() => {
          this.data.employees[employeeIndex]['category' + category].certificates.splice(certificateIndex, 1)
        })
      } catch (error) {
        console.error(error)
      }
    },
    updateTrainingHours (employeeIndex, category, trainingHours) {
      const employeeId = this.data.employees[employeeIndex].employeeId
      if (trainingHours === null) {
        this.api.delete(`/${employeeId}/${category}`)
      } else {
        this.update({name: `${employeeId}/${category}`, value: trainingHours})
      }

      this.data.employees[employeeIndex][`category${category}`].hours = trainingHours
    }
  }
}
</script>

<style lang="scss" scoped>
.training-grid {
  display: grid;
  grid-template-columns: 150px 50px 2fr 2fr 2fr;
  grid-template-areas: "category category category-1 category-2 category-3"
                       "display-examples display-examples category-1-description category-2-description category-3-description"
                       "employee-column employee-subgrid employee-subgrid employee-subgrid employee-subgrid";

  a:hover {
    cursor: pointer;
  }

  .grid-header {
    text-transform: uppercase;
  }

  .column-with-border {
    border-left: 1px solid gray;
    padding-left: 5px;
  }

  .category, .display-examples {
    grid-column-start: 2;
    grid-column-end: 3;
    justify-self: end;
    padding-right: 5px;
  }

  .employee-column {
    grid-column-start: 1;
    grid-column-end: 2;
    border-top: 1px solid gray;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0.25em 0.5em;
  }

  .employee-subgrid {
    grid-area: employee-subgrid;
    border-top: 1px solid gray;
    display: grid;
    grid-template-columns: 50px 2fr 2fr 2fr;

    .employee-name {
      word-break: break-word;
      padding: 0.25em 0.5em;
      display: flex;
      align-items: center;
    }

    .employee-cell:nth-child(8n+5), .employee-cell:nth-child(8n+6), .employee-cell:nth-child(8n+7), .employee-cell:nth-child(8n+8) {
      background-color: lighten(lightgray, 10%);
    }
  }

}
</style>
